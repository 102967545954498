<script>
import { ref } from 'vue'
import ui from '/~/core/ui'
import DefaultDiningCatalog from '/~/extensions/dining/templates/default/views/catalog/dining-catalog.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import DiningTile from './components/dining-tile.vue'

export default {
  name: 'dining-catalog',
  components: {
    DiningFilter: ui.mobile
      ? () =>
          import(
            '/~/extensions/dining/templates/default/views/catalog/components/dining-filter.mobile.vue'
          )
      : () => import('./components/dining-filter.vue'),
    DiningTile,
    BaseIcon,
  },
  extends: DefaultDiningCatalog,
  setup() {
    const hasShadow = ref(false)
    const scrollarea = ref(null)
    const showBackToTop = ref(false)

    function onScroll(event) {
      hasShadow.value = event.target?.scrollTop > 0

      if (event.target?.scrollTop > 500) {
        showBackToTop.value = true
      } else {
        showBackToTop.value = false
      }
    }

    function scrollTop() {
      scrollarea.value.$el.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return {
      ...DefaultDiningCatalog.setup?.(...arguments),
      hasShadow,
      onScroll,
      scrollarea,
      scrollTop,
      showBackToTop,
      ui,
    }
  },
}
</script>

<template>
  <div class="mx-auto flex h-full w-full flex-col">
    <widgets-builder v-if="page && page.widgets" :widgets="page.widgets" />

    <div
      class="flex flex-col"
      :class="{
        'h-full overflow-hidden': mapState.isVisible,
      }"
    >
      <dining-filter
        class="transition-color sticky top-0 z-30 w-full border-b bg-eonx-neutral-50 duration-300 sm:pb-2.5"
        :class="[!hasShadow && 'border-transparent']"
      />

      <layout-map
        title="Select location near me"
        :map-state="mapState"
        class="h-screen grow sm:h-full sm:px-0"
        :class="{
          'mx-auto w-full max-w-7xl overflow-hidden sm:pr-7':
            mapState.isVisible,
        }"
      >
        <div
          class="relative h-full w-full"
          :class="{
            'min-w-48 max-w-md': mapState.isVisible,
          }"
        >
          <ew-catalog-processor
            ref="scrollarea"
            class="mx-auto h-full w-full max-w-7xl divide-y p-5 sm:px-0 sm:pt-0"
            :class="{
              'overflow-auto': mapState.isVisible,
            }"
            :tile-class="`hover:shadow-lg bg-light ${
              mapState.isVisible
                ? 'hover:translate-x-[5px]'
                : 'hover:-translate-y-[5px]'
            }`"
            :processor="algolia"
            :columns="
              mapState.isVisible && !ui.mobile
                ? 1
                : {
                    xxs: 2,
                    sm: 3,
                    lg: 4,
                  }
            "
            @scroll="onScroll"
          >
            <template #empty>
              <div class="p-5 text-center">
                <p class="text-xl">
                  Sorry, we couldn't find any restaurants in your area.
                </p>
                <base-button class="mt-5" @click="showAllRestaurants">
                  Show all restaurants
                </base-button>
              </div>
            </template>

            <template #tile="{ tile }">
              <dining-tile :location="tile" />
            </template>
          </ew-catalog-processor>

          <button
            v-if="showBackToTop && !ui.mobile"
            class="absolute bottom-11 right-2.5 z-10 flex cursor-pointer items-center rounded bg-primary px-[15px] py-2.5 text-sm font-bold text-white hover:bg-primary-darken hover:opacity-90 hover:shadow sm:right-10 sm:py-[5px]"
            @click="scrollTop"
          >
            <base-icon svg="plain/arrow-up.v2" :size="16" />
            <span>Back to top</span>
          </button>
        </div>

        <template #map>
          <base-map
            class="h-full w-full max-w-7xl pt-5"
            :markers="markers"
            :state="mapState"
          />
        </template>
      </layout-map>
    </div>
  </div>
</template>
